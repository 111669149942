import React from "react"
import { Container, Box } from "theme-ui"
import Divider from "@components/Divider"
import CardList from "@components/CardList"
import { graphql, useStaticQuery } from "gatsby"
import PageTitle from "../components/Shared/PageTitle"
import { Stack } from "@layout"
import "./animation.css"

const styles = {
  wrapper: {
    position: `relative`,
  },
  container: {
    position: `relative`,
  },
  heading: {
    textAlign: "center",
    fontSize: "32px",
    margin: "20px 0 0 0",
  },
}

const productsQuery = graphql`
  query ProductMarkdowns {
    allMarkdownRemark(
      filter: {
        frontmatter: { templateKey: { in: ["products-page", "product-page"] } }
      }
    ) {
      edges {
        node {
          frontmatter {
            heading
            templateKey
            thumbnailSrc
            title
          }
          id
          excerpt
          fields {
            slug
          }
        }
      }
    }
  }
`

export default function () {
  const data = useStaticQuery(productsQuery)
  const productsData = data.allMarkdownRemark.edges.filter(
    edge => edge.node.frontmatter.templateKey === "product-page"
  )
  const products = productsData.map(dataItem => {
    const node = dataItem.node
    const { id, excerpt } = node
    const { title, thumbnailSrc, heading, templateKey } = node.frontmatter
    const { slug } = node.fields
    return {
      id,
      title,
      thumbnailSrc,
      heading,
      templateKey,
      excerpt,
      slug,
    }
  })

  const head = `Products`
  const suhead = `Through hundreds of engagements with Quick Base customers around the world, we have put together a set of tools that fills some key gaps in native Quick Base operations. Now, you can have the benefit of these tools.`

  return (
    <Box sx={styles.wrapper}>
      <div className="animateInDown">
        <Container variant="compact" sx={styles.container}>
          <Divider space={3} />
          <PageTitle header={head} subheader={suhead} />
          <Divider space={3} />
          <div style={{ marginBottom: "30px" }}>
            <CardList
              nodes={products}
              variant={["horizontal", "vertical"]}
              columns={[1, 2, 3, 3]}
            />
          </div>
        </Container>
      </div>
    </Box>
  )
}
